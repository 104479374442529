import React from 'react'
import PageWrapper from "../components/PageWrapper";
import NoseContent from '../sections/removal/removalcontent.js';
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Skins from "../sections/removal/removalskins";
import Facts from "../sections/removal/removalfacts.js";
import FAQ from "../sections/removal/removalfaq.js";
import Cost from "../sections/removal/removalcost.js";
import Did from "../sections/removal/removaldid.js";
import Anatomy from "../sections/removal/removalbody.js";
import How from "../sections/removal/removalhow.js";
import Gallery from "../sections/removal/removalgallery.js";
import Award from "../components/NewToxane.js";
import Trend from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/removal-hero.jpg";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import Testimonial from "../sections/index/indexreviews.js";
import { Box, Section } from "../components/Core";

const LaserRemovalPage = () => ( 
  <>
 <Head title="Laser Hair Removal" image="/images/dermamina-home.jpg"  description="Say goodbye to unwanted hair with laser hair removal, a precise and efficient solution that targets hair follicles for long-lasting results." keywords="Laser Hair Removal, Hair Laser, LAser Hair removal London, Laser Hair Removal harley street, remove hair laser"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Laser Hair Removal',
item:'https://www.dermamina.com/laser-hair-removal',
},


]
}}
</JsonLd>


<JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is Laser Hair Removal?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Laser hair removal is a medical procedure that uses a concentrated beam of light (laser) to remove unwanted hair. The laser emits a light absorbed by the pigment (melanin) in the hair. This light energy is converted to heat, damaging the hair follicles that produce hairs. This damage inhibits or delays future hair growth."
            }
          },
          {
            "@type": "Question",
            "name": "Is Laser Hair Removal Safe?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, laser hair removal is safe when performed by a trained professional. At Dermamina, we use FDA-approved and medical-grade equipment designed to provide effective and safe results. Each treatment is tailored to the patient's skin type and hair color to maximize safety and efficacy."
            }
          },
          {
            "@type": "Question",
            "name": "How Many Sessions Will I Need?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The number of sessions required varies depending on the area being treated, hair type, and individual response to the treatment. Typically, patients may need between 6 to 8 sessions to achieve optimal results. These sessions are usually spaced 4-6 weeks apart to coincide with the hair growth cycle."
            }
          },
          {
            "@type": "Question",
            "name": "Does Laser Hair Removal Hurt?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Pain tolerance varies by individual, but most patients describe the sensation as a rubber band snapping against the skin. Dermamina uses advanced equipment with cooling mechanisms to minimize discomfort."
            }
          }
        ]
      }}
    </JsonLd>

    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "AggregateRating",
        "ratingValue": "4.9",
        "reviewCount": "603"
      }}
    </JsonLd>


    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "Service",
        "serviceType": "Laser Hair Removal",
        "provider": {
          "@type": "Organization",
          "name": "Dermamina",
          "url": "https://www.dermamina.com"
        },
        "areaServed": {
          "@type": "Place",
          "name": "London"
        },
        "description": "Laser Hair Removal Treatment London",
        "offers": {
          "@type": "Offer",
          "url": "https://www.dermamina.com/laser-hair-removal",
          "priceCurrency": "GBP",
          "price": "49",
          "eligibleRegion": {
            "@type": "Place",
            "name": "London"
          }
        }
      }}
    </JsonLd>


    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "ItemList",
        "itemListElement": [
          {
            "@type": "ImageObject",
            "position": 1,
            "name": "Before and After Laser Hair Removal",
            "contentUrl": "https://www.dermamina.com/static/laser5-61161f8d7e691a745f680563257e2800.jpg",
            "description": "Image showing hair condition before and after Laser Hair Removal treatment."
          },
          {
            "@type": "ImageObject",
            "position": 2,
            "name": "Before and After Laser Hair Removal",
            "contentUrl": "https://www.dermamina.com/static/laser1-41333e2018ad2e449ab4b3d3c3b23819.jpg",
            "description": "Image showing hair condition before and after Laser Hair Removal treatment."
          }
        ]
      }}
    </JsonLd>


      <PageWrapper headerDark footerDark>
      <HeroComponent
    itext='<span style="color: #1a1a1a;">Laser </span> <span style="color: #00aec7;">Hair Removal</span>'
    secondText="Achieve Smooth Skin Effortlessly"
    videoAlt="Laser Hair Removal"
    imgVideo={imgVideo} // Specify your image URL here
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="6 - 12 treatments"
  bestalt="best Laser Hair Removal"
  resultstext="Long Lasting"
  resultsalt="Laser Hair Removal results"
  costdata="From £49"
  costalt="Laser Hair Removal Cost"
  timedata="From 10 Minutes"
  timealt="Laser Hair Removal Duration Time"
  workdata="Immediately"
  workalt="Laser Hair Removal downtime work"
  paindata="Minimal"
  painalt="Laser Hair Removal Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>

     <Gallery />
     <Award />
      <NoseContent />
      <Skins />
      <Testimonial />
     
      <Cost />
      <Facts />
      <Did />

      <How />
      <Anatomy />
      <FAQ />
      <Clinic />
      <Saveface />
      <Trend />
 
     </PageWrapper>
 
  </>
)
export default LaserRemovalPage
